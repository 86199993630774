// required for base
@import 'ac-sasskit/core';
// base (reset, grid, icons, and basic styles)
@import 'ac-sasskit/base';

.chiclet {
  width: 40px;
  height: 40px;
  display: grid;
  align-items: center;
  justify-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.glyphListItemIcon {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 25px;
  width: 25px;
  & svg {
    fill: swatch(fill-blue);
  }
}

.continueOnDevice {
  margin-top: 50px;
}
